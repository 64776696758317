<template>
  <v-simple-table class="default-table">
    <template v-slot:default>
      <tbody>
        <tr>
          <th class="text-left" rowspan="2">
            <div>0 - oznacza brak obniżenia</div>
            <div>
              4 - oznacza całkowite wypadnięcie na całej długości pochwy
            </div>
          </th>
          <th class="text-center">Data</th>
          <th class="text-center">Ocena</th>
        </tr>
        <tr>
          <td class="dtrow">
            <LabelDateField
              :value.sync="value.date"
              :disabled="disabled"
              placeholder="Wybierz..."
              flat
              hideDetails
              noMargins
              solo
            ></LabelDateField>
          </td>
          <td>
            <v-text-field
              v-model="value.scale"
              :disabled="disabled"
              solo
              flat
              placeholder="Wpisz..."
              v-mask="validateInput"
              hide-details
            ></v-text-field>
          </td>
        </tr>
        <tr>
          <th class="text-center">Uwagi</th>
          <TextAreaCell
            colspan="2"
            :value.sync="value.comment"
            placeholder="Wpisz uwagi..."
            :disabled="disabled"
          ></TextAreaCell>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LabelDateField: () => import("@/components/LabelDateField"),
    TextAreaCell: () => import("@/components/tables/TextAreaCell"),
  },
  methods: {
    validateInput(value) {
      if (/^[0-4]$/.test(value)) {
        return [value];
      } else if (/^[0-4]/.test(value)) {
        return [value[0]];
      } else {
        return [''];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.default-table {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
}
.default-table ::v-deep td {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  min-width: 90px;
}
.default-table ::v-deep input {
  text-align: center;
}
.default-table ::v-deep th {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
  color: black !important;
  font-size: 14px !important;
}
.default-table ::v-deep tr:hover {
  background-color: transparent !important;
}
.dtrow {
  min-width: 125px !important;
}
</style>